<template>
  <div>
    <div class="warp">
      <div class="right_content">
        <formDialog ref="formDialogDialogRef"
                    :parentForm="form"
                    :selects="selects"
                    :name="name" />
        <Independent ref="IndependentRef"
                     :name="name" />
        <el-row :gutter="10">
          <el-col :span="12">
            <el-button @click="checkForm"
                       type="primary">保存</el-button>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
import Independent from '../independent.vue'
import formDialog from '../formComponent.vue'
export default {
  components: {
    Independent, formDialog,
  },
  data () {
    return {
      name: '',
      info: {},
      selects: [],
      form: {
        subject_id: ''
      },
    }
  },
  created () {
    this.getInfo()
  },
  methods: {
    checkForm () {
      this.$refs.formDialogDialogRef.validateForm()
    },
    save1 () {
      this.$refs.IndependentRef.validateForm()
    },
    getInfo () {
      this.$http({
        url: '/api/v1/question/show',
        method: 'GET',
        params: {
          id: this.$route.query.id
        }
      }).then(res => {
        this.info = res.data.list
        this.getSelectList()
        if (Number(this.info.question_score) == 0) {
          this.name = '本题就是一个题头'
        } else if (this.info.question_code_head) {
          this.name = '本题带有题头'
        } else {
          this.name = '无题头，独立选择'
        }
        let info = this.info
        this.form.subject_id = this.info.subject_id
        console.log(this.form.subject_id)
        this.subjetChange(this.form.subject_id)
        let copyForm = {
          questionCode: info.question_code,
          questionEnable: info.question_enable,
          questionCodeHead: info.question_code_head,
          questionSerialHead: info.question_serial_head,
          questionType: info.question_type_id,
          questionCategory: info.question_category_id,
          questionDifficulty: info.question_difficulty,
          questionScore: info.question_score,
          questionId: info.question_id,
          questionSource: info.source_id,
          questionYear: info.question_year,
          questionDifficulty: info.question_difficulty,
          questionCodeHead: info.question_code_head,
          spinnerSerialHead: info.question_serial_head,
          subject_id: info.subject_id,
          capability_data: info.capability_data
        }
        //重构级联选择器所需要的数据结构  因为在保存时做了扁平化处理
        let arr = []
        copyForm.capability_data.map((item) => {
          return item.map((k, index) => {
            if (index > 0) {
              console.log('[item[0], k]', [item[0], k])
              arr.push([item[0], k])
            }
          });
        }).filter((element) => element !== undefined);
         copyForm.capability_data = arr

        this.$refs.formDialogDialogRef.form = copyForm

        // 回显知识点
        this.setPoint()
        let topicForm = {
          questionContent: this.info.question_content,
          questionExplain: this.info.question_explain,
          questionAnswer: this.info.question_answer
        }
        this.$refs.IndependentRef.form = topicForm

      })
    },
    getSelectList () {
      this.$http({
        url: '/api/v1/question/choices',
        method: 'POST',
        data: {
          source_id: this.info.source_id,
          noCheckpoint: 0,
          subject_id: this.info.subject_id
        }
      }).then(res => {
        this.selects = res.data
        this.form.subject_id = this.selects.paper_subject[0]
      })
    },
    subjetChange (val) {
      this.form.subject_id = val
      this.$refs.formDialogDialogRef.subject_id = this.form.subject_id
    },

    setPoint () {
      let arr = []
      this.info.knowledge_point.forEach(item => {
        let obj = {
          knowledge_id: item.knowledge_id,
          knowledge_score: item.knowledge_score,
          knowledge_parent_id: item.knowledge_parent_id,
          label: item.knowledge_desc,
          level: item.level
        }
        arr.push(obj)
      })
      this.$refs.formDialogDialogRef.setPoint(arr)
    },

    save () {
      let form = JSON.parse(JSON.stringify(this.$refs.formDialogDialogRef.form))
      form = Object.assign(form, this.$refs.IndependentRef.form)
      // form.capability = form.capability_data
      form.capability = new Set(form.capability_data.flat(Infinity))
      form.capability = Array.from(form.capability);
      delete form.capability_data
      delete form.check_point
      delete form.knowledge_point
      delete form.checkpoints2
      this.$http({
        url: '/api/v1/question/edit',
        method: 'POST',
        data: form
      }).then(res => {
        this.$notify({
          title: '提示',
          message: '修改成功!',
          type: 'success'
        });
        if (this.$route.query.isProofread) {
          this.$router.push('/examinationEntry/proofread?form=' + this.$route.query.form)
          return
        }
        this.$router.go(-1)
      })
    }

  }
}
</script>

<style lang="scss" scoped>
.warp {
  display: flex;
  justify-content: space-between;
  background: #f2f2f2;
  overflow: auto;
  .left_tree {
    padding: 10px;
    width: 11.42%;
    background: white;
    border-top: 2px solid #f0f2f5;
  }
  .right_content {
    padding: 1%;
    padding-bottom: 50px;

    flex: 1;
    overflow: auto;
    background: white;
  }
}
</style>