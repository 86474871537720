<template>
  <div>
    <el-form :model="form"
             ref="formRefs"
             :rules="rules"
             label-width="100px">
      <el-row :gutter="10">
        <el-col :span="24">
          <el-row :gutter="10">
            <el-col :span="24">
              <el-form-item label="题目内容"
                            prop="questionContent">
                <vue-ueditor-wrap v-model="form.questionContent"
                                  :config="$config"></vue-ueditor-wrap>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="10"
                  v-if="!(name == '本题就是一个题头')">
            <el-col :span="24">
              <el-form-item label="题目答案"
                            prop="questionAnswer">
                <vue-ueditor-wrap v-model="form.questionAnswer"
                                  :config="$config"></vue-ueditor-wrap>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <el-col :span="24">
              <el-form-item label="题目解释"
                            v-if="!(name == '本题就是一个题头')"
                            prop="questionExplain">
                <vue-ueditor-wrap v-model="form.questionExplain"
                                  :config="$config"></vue-ueditor-wrap>
              </el-form-item>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
    </el-form>

  </div>
</template>

<script>
export default {
  data () {
    return {
      form: {
        questionContent: '',
        questionAnswer: '',
        questionExplain: '',
      },
      width: 10,
      rules: {
        questionContent: [
          { required: true, message: '请输入', trigger: 'change' },
        ],
        questionAnswer: [
          { required: true, message: '请输入', trigger: 'change' },
        ],
        questionExplain: [
          { required: true, message: '请输入', trigger: 'change' },
        ],
      }
    }
  },
  props: {
    name: {
      type: String,
      default: ''
    }
  },
  watch: {
    form: {
      handler () {
        this.form.questionContent = this.$replaceAll(this.form.questionContent)
        this.form.questionAnswer = this.$replaceAll(this.form.questionAnswer)
        this.form.questionExplain = this.$replaceAll(this.form.questionExplain)
        setTimeout(() => {
          this.num = 1
        }, 300);
      },
      deep: true
    },
    'form.questionContent': {
      handler (newVal, oldVal) {
        if (this.$route.path == '/examinationEntry/examinationEntry/edit' && this.num == 0) {
          return
        }
        if (!newVal) {
          newVal = ''
        }
        if (!oldVal) {
          oldVal = ''
        }
        if (newVal.length - oldVal.length <= 100) {
          return
        }
        var dom = 'view_warp'
        if (this.$route.path == '/examinationEntry/examinationEntry/add') {
          dom = 'right_content'
        }
        var owarp = document.getElementsByClassName(dom)[0]

        setTimeout(() => {
          owarp.scrollTop = document.getElementsByClassName('edui-editor')[0].clientHeight - 100
        }, 200);
      },
      deep: true
    },
    'form.questionAnswer': {
      handler (newVal, oldVal) {
        if (this.$route.path == '/examinationEntry/examinationEntry/edit' && this.num == 0) {
          return
        }
        if (!newVal) {
          newVal = ''
        }
        if (!oldVal) {
          oldVal = ''
        }
        if (newVal.length - oldVal.length <= 100) {
          return
        }
        var dom = 'view_warp'
        if (this.$route.path == '/examinationEntry/examinationEntry/add') {
          dom = 'right_content'
        }
        var owarp = document.getElementsByClassName(dom)[0]
        var oedit0Height = document.getElementsByClassName('edui-editor')[0].clientHeight
        setTimeout(() => {
          owarp.scrollTop = document.getElementsByClassName('edui-editor')[1].clientHeight + oedit0Height
        }, 200);
      },
      deep: true
    },
    'form.questionExplain': {
      handler (newVal, oldVal) {
        if (this.$route.path == '/examinationEntry/examinationEntry/edit' && this.num == 0) {
          return
        }
        if (!newVal) {
          newVal = ''
        }
        if (!oldVal) {
          oldVal = ''
        }
        if (newVal.length - oldVal.length <= 100) {
          return
        }
        var dom = 'view_warp'
        if (this.$route.path == '/examinationEntry/examinationEntry/add') {
          dom = 'right_content'
        }
        var owarp = document.getElementsByClassName(dom)[0]
        setTimeout(() => {
          owarp.scrollTop = owarp.scrollHeight
        }, 200);
      },
      deep: true
    },
  },
  methods: {
    refresh () {
      this.$refs.formRefs.resetFields()
    },
    validateForm () {
      this.$refs.formRefs.validate(val => {
        if (!val) {
          return
        } else {
          this.$parent.save()
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-radio__label {
  display: none;
}
</style>